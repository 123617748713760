/**
 * @fileoverview gRPC-Web generated client stub for commUnity
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.3
// source: mobile.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as commUnity_pb from './commUnity_pb'; // proto import: "commUnity.proto"
import * as mobile_pb from './mobile_pb'; // proto import: "mobile.proto"
import * as shop_pb from './shop_pb'; // proto import: "shop.proto"
import * as restaurant_pb from './restaurant_pb'; // proto import: "restaurant.proto"
import * as hotix_pb from './hotix_pb'; // proto import: "hotix.proto"
import * as forum_pb from './forum_pb'; // proto import: "forum.proto"


export class MobileClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetAlbums = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetAlbums',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.Album,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.Album.deserializeBinary
  );

  getAlbums(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.Album> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetAlbums',
      request,
      metadata || {},
      this.methodDescriptorGetAlbums);
  }

  methodDescriptorGetAds = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetAds',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.Ad,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.Ad.deserializeBinary
  );

  getAds(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.Ad> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetAds',
      request,
      metadata || {},
      this.methodDescriptorGetAds);
  }

  methodDescriptorGetAlerts = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetAlerts',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.Alert,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.Alert.deserializeBinary
  );

  getAlerts(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.Alert> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetAlerts',
      request,
      metadata || {},
      this.methodDescriptorGetAlerts);
  }

  methodDescriptorGetLeaderBoard = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetLeaderBoard',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.Leaderboard,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.Leaderboard.deserializeBinary
  );

  getLeaderBoard(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.Leaderboard> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetLeaderBoard',
      request,
      metadata || {},
      this.methodDescriptorGetLeaderBoard);
  }

  methodDescriptorGetNews = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetNews',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.News,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.News.deserializeBinary
  );

  getNews(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.News> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetNews',
      request,
      metadata || {},
      this.methodDescriptorGetNews);
  }

  methodDescriptorGetNews2 = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetNews2',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.News,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.News.deserializeBinary
  );

  getNews2(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.News> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetNews2',
      request,
      metadata || {},
      this.methodDescriptorGetNews2);
  }

  methodDescriptorGetNews3 = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetNews3',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.News,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.News.deserializeBinary
  );

  getNews3(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.News> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetNews3',
      request,
      metadata || {},
      this.methodDescriptorGetNews3);
  }

  methodDescriptorGetNews4 = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetNews4',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.News,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.News.deserializeBinary
  );

  getNews4(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.News> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetNews4',
      request,
      metadata || {},
      this.methodDescriptorGetNews4);
  }

  methodDescriptorGetGolfTeachers = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetGolfTeachers',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.GolfTeacher,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.GolfTeacher.deserializeBinary
  );

  getGolfTeachers(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.GolfTeacher> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetGolfTeachers',
      request,
      metadata || {},
      this.methodDescriptorGetGolfTeachers);
  }

  methodDescriptorGetGolfThemes = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetGolfThemes',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.GolfTheme,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.GolfTheme.deserializeBinary
  );

  getGolfThemes(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.GolfTheme> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetGolfThemes',
      request,
      metadata || {},
      this.methodDescriptorGetGolfThemes);
  }

  methodDescriptorGetGolfClassTypes = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetGolfClassTypes',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.GolfClassType,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.GolfClassType.deserializeBinary
  );

  getGolfClassTypes(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.GolfClassType> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetGolfClassTypes',
      request,
      metadata || {},
      this.methodDescriptorGetGolfClassTypes);
  }

  methodDescriptorGetGolfClassLevels = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetGolfClassLevels',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.GolfClassLevel,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.GolfClassLevel.deserializeBinary
  );

  getGolfClassLevels(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.GolfClassLevel> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetGolfClassLevels',
      request,
      metadata || {},
      this.methodDescriptorGetGolfClassLevels);
  }

  methodDescriptorGetGolfClasses = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetGolfClasses',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.GolfClassesQuery,
    commUnity_pb.GolfClass,
    (request: commUnity_pb.GolfClassesQuery) => {
      return request.serializeBinary();
    },
    commUnity_pb.GolfClass.deserializeBinary
  );

  getGolfClasses(
    request: commUnity_pb.GolfClassesQuery,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.GolfClass> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetGolfClasses',
      request,
      metadata || {},
      this.methodDescriptorGetGolfClasses);
  }

  methodDescriptorGetMyGolfClasses = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetMyGolfClasses',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.GolfClass,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.GolfClass.deserializeBinary
  );

  getMyGolfClasses(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.GolfClass> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetMyGolfClasses',
      request,
      metadata || {},
      this.methodDescriptorGetMyGolfClasses);
  }

  methodDescriptorAttendGolfClass = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/AttendGolfClass',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.RequestID,
    commUnity_pb.Empty,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    commUnity_pb.Empty.deserializeBinary
  );

  attendGolfClass(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.Empty>;

  attendGolfClass(
    request: commUnity_pb.RequestID,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void): grpcWeb.ClientReadableStream<commUnity_pb.Empty>;

  attendGolfClass(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/AttendGolfClass',
        request,
        metadata || {},
        this.methodDescriptorAttendGolfClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/AttendGolfClass',
    request,
    metadata || {},
    this.methodDescriptorAttendGolfClass);
  }

  methodDescriptorCancelGolfClassReservation = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/CancelGolfClassReservation',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.RequestID,
    commUnity_pb.Empty,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    commUnity_pb.Empty.deserializeBinary
  );

  cancelGolfClassReservation(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.Empty>;

  cancelGolfClassReservation(
    request: commUnity_pb.RequestID,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void): grpcWeb.ClientReadableStream<commUnity_pb.Empty>;

  cancelGolfClassReservation(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/CancelGolfClassReservation',
        request,
        metadata || {},
        this.methodDescriptorCancelGolfClassReservation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/CancelGolfClassReservation',
    request,
    metadata || {},
    this.methodDescriptorCancelGolfClassReservation);
  }

  methodDescriptorGenerateICSConfirmGolfClass = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GenerateICSConfirmGolfClass',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.RequestID,
    mobile_pb.ICS,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    mobile_pb.ICS.deserializeBinary
  );

  generateICSConfirmGolfClass(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null): Promise<mobile_pb.ICS>;

  generateICSConfirmGolfClass(
    request: commUnity_pb.RequestID,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: mobile_pb.ICS) => void): grpcWeb.ClientReadableStream<mobile_pb.ICS>;

  generateICSConfirmGolfClass(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: mobile_pb.ICS) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/GenerateICSConfirmGolfClass',
        request,
        metadata || {},
        this.methodDescriptorGenerateICSConfirmGolfClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/GenerateICSConfirmGolfClass',
    request,
    metadata || {},
    this.methodDescriptorGenerateICSConfirmGolfClass);
  }

  methodDescriptorGetCPSURL = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetCPSURL',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.Empty,
    mobile_pb.CPSURL,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    mobile_pb.CPSURL.deserializeBinary
  );

  getCPSURL(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<mobile_pb.CPSURL>;

  getCPSURL(
    request: commUnity_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: mobile_pb.CPSURL) => void): grpcWeb.ClientReadableStream<mobile_pb.CPSURL>;

  getCPSURL(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: mobile_pb.CPSURL) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/GetCPSURL',
        request,
        metadata || {},
        this.methodDescriptorGetCPSURL,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/GetCPSURL',
    request,
    metadata || {},
    this.methodDescriptorGetCPSURL);
  }

  methodDescriptorGetBenefits = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetBenefits',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.Benefit,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.Benefit.deserializeBinary
  );

  getBenefits(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.Benefit> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetBenefits',
      request,
      metadata || {},
      this.methodDescriptorGetBenefits);
  }

  methodDescriptorSearch = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/Search',
    grpcWeb.MethodType.SERVER_STREAMING,
    mobile_pb.SearchRequest,
    mobile_pb.SearchResult,
    (request: mobile_pb.SearchRequest) => {
      return request.serializeBinary();
    },
    mobile_pb.SearchResult.deserializeBinary
  );

  search(
    request: mobile_pb.SearchRequest,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<mobile_pb.SearchResult> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/Search',
      request,
      metadata || {},
      this.methodDescriptorSearch);
  }

  methodDescriptorForgotPassword = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/ForgotPassword',
    grpcWeb.MethodType.UNARY,
    mobile_pb.RequestPassword,
    commUnity_pb.Empty,
    (request: mobile_pb.RequestPassword) => {
      return request.serializeBinary();
    },
    commUnity_pb.Empty.deserializeBinary
  );

  forgotPassword(
    request: mobile_pb.RequestPassword,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.Empty>;

  forgotPassword(
    request: mobile_pb.RequestPassword,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void): grpcWeb.ClientReadableStream<commUnity_pb.Empty>;

  forgotPassword(
    request: mobile_pb.RequestPassword,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/ForgotPassword',
        request,
        metadata || {},
        this.methodDescriptorForgotPassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/ForgotPassword',
    request,
    metadata || {},
    this.methodDescriptorForgotPassword);
  }

  methodDescriptorGetMemberProfileSettings = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetMemberProfileSettings',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.Empty,
    commUnity_pb.MemberProfileSettings,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.MemberProfileSettings.deserializeBinary
  );

  getMemberProfileSettings(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.MemberProfileSettings>;

  getMemberProfileSettings(
    request: commUnity_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.MemberProfileSettings) => void): grpcWeb.ClientReadableStream<commUnity_pb.MemberProfileSettings>;

  getMemberProfileSettings(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.MemberProfileSettings) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/GetMemberProfileSettings',
        request,
        metadata || {},
        this.methodDescriptorGetMemberProfileSettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/GetMemberProfileSettings',
    request,
    metadata || {},
    this.methodDescriptorGetMemberProfileSettings);
  }

  methodDescriptorGetMemberSettings = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetMemberSettings',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.Empty,
    commUnity_pb.MemberProfileSettings,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.MemberProfileSettings.deserializeBinary
  );

  getMemberSettings(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.MemberProfileSettings>;

  getMemberSettings(
    request: commUnity_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.MemberProfileSettings) => void): grpcWeb.ClientReadableStream<commUnity_pb.MemberProfileSettings>;

  getMemberSettings(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.MemberProfileSettings) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/GetMemberSettings',
        request,
        metadata || {},
        this.methodDescriptorGetMemberSettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/GetMemberSettings',
    request,
    metadata || {},
    this.methodDescriptorGetMemberSettings);
  }

  methodDescriptorGetMemberTypes = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetMemberTypes',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.MemberType,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.MemberType.deserializeBinary
  );

  getMemberTypes(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.MemberType> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetMemberTypes',
      request,
      metadata || {},
      this.methodDescriptorGetMemberTypes);
  }

  methodDescriptorSignIn = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/SignIn',
    grpcWeb.MethodType.UNARY,
    mobile_pb.SignInMobileRequest,
    mobile_pb.MemberToken,
    (request: mobile_pb.SignInMobileRequest) => {
      return request.serializeBinary();
    },
    mobile_pb.MemberToken.deserializeBinary
  );

  signIn(
    request: mobile_pb.SignInMobileRequest,
    metadata?: grpcWeb.Metadata | null): Promise<mobile_pb.MemberToken>;

  signIn(
    request: mobile_pb.SignInMobileRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: mobile_pb.MemberToken) => void): grpcWeb.ClientReadableStream<mobile_pb.MemberToken>;

  signIn(
    request: mobile_pb.SignInMobileRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: mobile_pb.MemberToken) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/SignIn',
        request,
        metadata || {},
        this.methodDescriptorSignIn,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/SignIn',
    request,
    metadata || {},
    this.methodDescriptorSignIn);
  }

  methodDescriptorSignUp = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/SignUp',
    grpcWeb.MethodType.UNARY,
    mobile_pb.SignUpMobileRequest,
    commUnity_pb.SignUpToken,
    (request: mobile_pb.SignUpMobileRequest) => {
      return request.serializeBinary();
    },
    commUnity_pb.SignUpToken.deserializeBinary
  );

  signUp(
    request: mobile_pb.SignUpMobileRequest,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.SignUpToken>;

  signUp(
    request: mobile_pb.SignUpMobileRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.SignUpToken) => void): grpcWeb.ClientReadableStream<commUnity_pb.SignUpToken>;

  signUp(
    request: mobile_pb.SignUpMobileRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.SignUpToken) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/SignUp',
        request,
        metadata || {},
        this.methodDescriptorSignUp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/SignUp',
    request,
    metadata || {},
    this.methodDescriptorSignUp);
  }

  methodDescriptorSignOut = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/SignOut',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.Empty,
    commUnity_pb.Empty,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.Empty.deserializeBinary
  );

  signOut(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.Empty>;

  signOut(
    request: commUnity_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void): grpcWeb.ClientReadableStream<commUnity_pb.Empty>;

  signOut(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/SignOut',
        request,
        metadata || {},
        this.methodDescriptorSignOut,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/SignOut',
    request,
    metadata || {},
    this.methodDescriptorSignOut);
  }

  methodDescriptorGetMembers = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetMembers',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.RequestMembers,
    commUnity_pb.Member,
    (request: commUnity_pb.RequestMembers) => {
      return request.serializeBinary();
    },
    commUnity_pb.Member.deserializeBinary
  );

  getMembers(
    request: commUnity_pb.RequestMembers,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.Member> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetMembers',
      request,
      metadata || {},
      this.methodDescriptorGetMembers);
  }

  methodDescriptorUpdateProfile = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/UpdateProfile',
    grpcWeb.MethodType.UNARY,
    mobile_pb.MemberProfileRequest,
    commUnity_pb.Member,
    (request: mobile_pb.MemberProfileRequest) => {
      return request.serializeBinary();
    },
    commUnity_pb.Member.deserializeBinary
  );

  updateProfile(
    request: mobile_pb.MemberProfileRequest,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.Member>;

  updateProfile(
    request: mobile_pb.MemberProfileRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.Member) => void): grpcWeb.ClientReadableStream<commUnity_pb.Member>;

  updateProfile(
    request: mobile_pb.MemberProfileRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.Member) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/UpdateProfile',
        request,
        metadata || {},
        this.methodDescriptorUpdateProfile,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/UpdateProfile',
    request,
    metadata || {},
    this.methodDescriptorUpdateProfile);
  }

  methodDescriptorUpdateFCMToken = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/UpdateFCMToken',
    grpcWeb.MethodType.UNARY,
    mobile_pb.FCMToken,
    commUnity_pb.Empty,
    (request: mobile_pb.FCMToken) => {
      return request.serializeBinary();
    },
    commUnity_pb.Empty.deserializeBinary
  );

  updateFCMToken(
    request: mobile_pb.FCMToken,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.Empty>;

  updateFCMToken(
    request: mobile_pb.FCMToken,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void): grpcWeb.ClientReadableStream<commUnity_pb.Empty>;

  updateFCMToken(
    request: mobile_pb.FCMToken,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/UpdateFCMToken',
        request,
        metadata || {},
        this.methodDescriptorUpdateFCMToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/UpdateFCMToken',
    request,
    metadata || {},
    this.methodDescriptorUpdateFCMToken);
  }

  methodDescriptorGetGuestText = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetGuestText',
    grpcWeb.MethodType.UNARY,
    mobile_pb.GuestTextRequest,
    commUnity_pb.GuestText,
    (request: mobile_pb.GuestTextRequest) => {
      return request.serializeBinary();
    },
    commUnity_pb.GuestText.deserializeBinary
  );

  getGuestText(
    request: mobile_pb.GuestTextRequest,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.GuestText>;

  getGuestText(
    request: mobile_pb.GuestTextRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.GuestText) => void): grpcWeb.ClientReadableStream<commUnity_pb.GuestText>;

  getGuestText(
    request: mobile_pb.GuestTextRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.GuestText) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/GetGuestText',
        request,
        metadata || {},
        this.methodDescriptorGetGuestText,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/GetGuestText',
    request,
    metadata || {},
    this.methodDescriptorGetGuestText);
  }

  methodDescriptorGetMinimalVersion = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetMinimalVersion',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.Empty,
    mobile_pb.MinVersion,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    mobile_pb.MinVersion.deserializeBinary
  );

  getMinimalVersion(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<mobile_pb.MinVersion>;

  getMinimalVersion(
    request: commUnity_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: mobile_pb.MinVersion) => void): grpcWeb.ClientReadableStream<mobile_pb.MinVersion>;

  getMinimalVersion(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: mobile_pb.MinVersion) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/GetMinimalVersion',
        request,
        metadata || {},
        this.methodDescriptorGetMinimalVersion,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/GetMinimalVersion',
    request,
    metadata || {},
    this.methodDescriptorGetMinimalVersion);
  }

  methodDescriptorGetCustomerInfo = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetCustomerInfo',
    grpcWeb.MethodType.UNARY,
    mobile_pb.RequestCustomer,
    commUnity_pb.Customer,
    (request: mobile_pb.RequestCustomer) => {
      return request.serializeBinary();
    },
    commUnity_pb.Customer.deserializeBinary
  );

  getCustomerInfo(
    request: mobile_pb.RequestCustomer,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.Customer>;

  getCustomerInfo(
    request: mobile_pb.RequestCustomer,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.Customer) => void): grpcWeb.ClientReadableStream<commUnity_pb.Customer>;

  getCustomerInfo(
    request: mobile_pb.RequestCustomer,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.Customer) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/GetCustomerInfo',
        request,
        metadata || {},
        this.methodDescriptorGetCustomerInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/GetCustomerInfo',
    request,
    metadata || {},
    this.methodDescriptorGetCustomerInfo);
  }

  methodDescriptorGetGroup = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetGroup',
    grpcWeb.MethodType.UNARY,
    mobile_pb.MobApp,
    commUnity_pb.Group,
    (request: mobile_pb.MobApp) => {
      return request.serializeBinary();
    },
    commUnity_pb.Group.deserializeBinary
  );

  getGroup(
    request: mobile_pb.MobApp,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.Group>;

  getGroup(
    request: mobile_pb.MobApp,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.Group) => void): grpcWeb.ClientReadableStream<commUnity_pb.Group>;

  getGroup(
    request: mobile_pb.MobApp,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.Group) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/GetGroup',
        request,
        metadata || {},
        this.methodDescriptorGetGroup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/GetGroup',
    request,
    metadata || {},
    this.methodDescriptorGetGroup);
  }

  methodDescriptorGetCustomers = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetCustomers',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.RequestID,
    mobile_pb.GroupCustomers,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    mobile_pb.GroupCustomers.deserializeBinary
  );

  getCustomers(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null): Promise<mobile_pb.GroupCustomers>;

  getCustomers(
    request: commUnity_pb.RequestID,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: mobile_pb.GroupCustomers) => void): grpcWeb.ClientReadableStream<mobile_pb.GroupCustomers>;

  getCustomers(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: mobile_pb.GroupCustomers) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/GetCustomers',
        request,
        metadata || {},
        this.methodDescriptorGetCustomers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/GetCustomers',
    request,
    metadata || {},
    this.methodDescriptorGetCustomers);
  }

  methodDescriptorEventAttend = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/EventAttend',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.RequestID,
    commUnity_pb.Empty,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    commUnity_pb.Empty.deserializeBinary
  );

  eventAttend(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.Empty>;

  eventAttend(
    request: commUnity_pb.RequestID,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void): grpcWeb.ClientReadableStream<commUnity_pb.Empty>;

  eventAttend(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/EventAttend',
        request,
        metadata || {},
        this.methodDescriptorEventAttend,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/EventAttend',
    request,
    metadata || {},
    this.methodDescriptorEventAttend);
  }

  methodDescriptorEventNotAttend = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/EventNotAttend',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.RequestID,
    commUnity_pb.Empty,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    commUnity_pb.Empty.deserializeBinary
  );

  eventNotAttend(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.Empty>;

  eventNotAttend(
    request: commUnity_pb.RequestID,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void): grpcWeb.ClientReadableStream<commUnity_pb.Empty>;

  eventNotAttend(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/EventNotAttend',
        request,
        metadata || {},
        this.methodDescriptorEventNotAttend,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/EventNotAttend',
    request,
    metadata || {},
    this.methodDescriptorEventNotAttend);
  }

  methodDescriptorGetEvents = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetEvents',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    mobile_pb.EventMobile,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    mobile_pb.EventMobile.deserializeBinary
  );

  getEvents(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<mobile_pb.EventMobile> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetEvents',
      request,
      metadata || {},
      this.methodDescriptorGetEvents);
  }

  methodDescriptorGetCompetitions = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetCompetitions',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.Event,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.Event.deserializeBinary
  );

  getCompetitions(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.Event> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetCompetitions',
      request,
      metadata || {},
      this.methodDescriptorGetCompetitions);
  }

  methodDescriptorGetPlaces = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetPlaces',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.RequestID,
    commUnity_pb.Place,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    commUnity_pb.Place.deserializeBinary
  );

  getPlaces(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.Place> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetPlaces',
      request,
      metadata || {},
      this.methodDescriptorGetPlaces);
  }

  methodDescriptorGetPosts = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetPosts',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    forum_pb.Post,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    forum_pb.Post.deserializeBinary
  );

  getPosts(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<forum_pb.Post> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetPosts',
      request,
      metadata || {},
      this.methodDescriptorGetPosts);
  }

  methodDescriptorGetTopics = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetTopics',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    forum_pb.Topic,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    forum_pb.Topic.deserializeBinary
  );

  getTopics(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<forum_pb.Topic> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetTopics',
      request,
      metadata || {},
      this.methodDescriptorGetTopics);
  }

  methodDescriptorAddPost = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/AddPost',
    grpcWeb.MethodType.UNARY,
    forum_pb.NewPost,
    commUnity_pb.Empty,
    (request: forum_pb.NewPost) => {
      return request.serializeBinary();
    },
    commUnity_pb.Empty.deserializeBinary
  );

  addPost(
    request: forum_pb.NewPost,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.Empty>;

  addPost(
    request: forum_pb.NewPost,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void): grpcWeb.ClientReadableStream<commUnity_pb.Empty>;

  addPost(
    request: forum_pb.NewPost,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/AddPost',
        request,
        metadata || {},
        this.methodDescriptorAddPost,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/AddPost',
    request,
    metadata || {},
    this.methodDescriptorAddPost);
  }

  methodDescriptorGetLinks = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetLinks',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.Link,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.Link.deserializeBinary
  );

  getLinks(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.Link> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetLinks',
      request,
      metadata || {},
      this.methodDescriptorGetLinks);
  }

  methodDescriptorGetLinks2 = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetLinks2',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.Link,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.Link.deserializeBinary
  );

  getLinks2(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.Link> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetLinks2',
      request,
      metadata || {},
      this.methodDescriptorGetLinks2);
  }

  methodDescriptorGetLinks3 = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetLinks3',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.Link,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.Link.deserializeBinary
  );

  getLinks3(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.Link> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetLinks3',
      request,
      metadata || {},
      this.methodDescriptorGetLinks3);
  }

  methodDescriptorGetLinks4 = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetLinks4',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.Link,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.Link.deserializeBinary
  );

  getLinks4(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.Link> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetLinks4',
      request,
      metadata || {},
      this.methodDescriptorGetLinks4);
  }

  methodDescriptorGetStreams = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetStreams',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.Stream,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.Stream.deserializeBinary
  );

  getStreams(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.Stream> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetStreams',
      request,
      metadata || {},
      this.methodDescriptorGetStreams);
  }

  methodDescriptorGetStreams2 = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetStreams2',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.Stream,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.Stream.deserializeBinary
  );

  getStreams2(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.Stream> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetStreams2',
      request,
      metadata || {},
      this.methodDescriptorGetStreams2);
  }

  methodDescriptorGetStreams3 = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetStreams3',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.Stream,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.Stream.deserializeBinary
  );

  getStreams3(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.Stream> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetStreams3',
      request,
      metadata || {},
      this.methodDescriptorGetStreams3);
  }

  methodDescriptorGetStreams4 = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetStreams4',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.Stream,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.Stream.deserializeBinary
  );

  getStreams4(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.Stream> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetStreams4',
      request,
      metadata || {},
      this.methodDescriptorGetStreams4);
  }

  methodDescriptorGetCustomizedMenus = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetCustomizedMenus',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    commUnity_pb.CustomizedMenu,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.CustomizedMenu.deserializeBinary
  );

  getCustomizedMenus(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.CustomizedMenu> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetCustomizedMenus',
      request,
      metadata || {},
      this.methodDescriptorGetCustomizedMenus);
  }

  methodDescriptorGetMenuAlias = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetMenuAlias',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.Empty,
    commUnity_pb.MenuAlias,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.MenuAlias.deserializeBinary
  );

  getMenuAlias(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.MenuAlias>;

  getMenuAlias(
    request: commUnity_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.MenuAlias) => void): grpcWeb.ClientReadableStream<commUnity_pb.MenuAlias>;

  getMenuAlias(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.MenuAlias) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/GetMenuAlias',
        request,
        metadata || {},
        this.methodDescriptorGetMenuAlias,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/GetMenuAlias',
    request,
    metadata || {},
    this.methodDescriptorGetMenuAlias);
  }

  methodDescriptorGetBusinessCards = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetBusinessCards',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.RequestID,
    commUnity_pb.BusinessCard,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    commUnity_pb.BusinessCard.deserializeBinary
  );

  getBusinessCards(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.BusinessCard> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetBusinessCards',
      request,
      metadata || {},
      this.methodDescriptorGetBusinessCards);
  }

  methodDescriptorGetMemberBusinessCard = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetMemberBusinessCard',
    grpcWeb.MethodType.UNARY,
    mobile_pb.GetMemberBusinessCardRequest,
    commUnity_pb.MemberBusinessCard,
    (request: mobile_pb.GetMemberBusinessCardRequest) => {
      return request.serializeBinary();
    },
    commUnity_pb.MemberBusinessCard.deserializeBinary
  );

  getMemberBusinessCard(
    request: mobile_pb.GetMemberBusinessCardRequest,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.MemberBusinessCard>;

  getMemberBusinessCard(
    request: mobile_pb.GetMemberBusinessCardRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.MemberBusinessCard) => void): grpcWeb.ClientReadableStream<commUnity_pb.MemberBusinessCard>;

  getMemberBusinessCard(
    request: mobile_pb.GetMemberBusinessCardRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.MemberBusinessCard) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/GetMemberBusinessCard',
        request,
        metadata || {},
        this.methodDescriptorGetMemberBusinessCard,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/GetMemberBusinessCard',
    request,
    metadata || {},
    this.methodDescriptorGetMemberBusinessCard);
  }

  methodDescriptorScanMemberBusinessCard = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/ScanMemberBusinessCard',
    grpcWeb.MethodType.UNARY,
    mobile_pb.ScanMemberBusinessCardRequest,
    commUnity_pb.MemberBusinessCard,
    (request: mobile_pb.ScanMemberBusinessCardRequest) => {
      return request.serializeBinary();
    },
    commUnity_pb.MemberBusinessCard.deserializeBinary
  );

  scanMemberBusinessCard(
    request: mobile_pb.ScanMemberBusinessCardRequest,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.MemberBusinessCard>;

  scanMemberBusinessCard(
    request: mobile_pb.ScanMemberBusinessCardRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.MemberBusinessCard) => void): grpcWeb.ClientReadableStream<commUnity_pb.MemberBusinessCard>;

  scanMemberBusinessCard(
    request: mobile_pb.ScanMemberBusinessCardRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.MemberBusinessCard) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/ScanMemberBusinessCard',
        request,
        metadata || {},
        this.methodDescriptorScanMemberBusinessCard,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/ScanMemberBusinessCard',
    request,
    metadata || {},
    this.methodDescriptorScanMemberBusinessCard);
  }

  methodDescriptorGetDocuments = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetDocuments',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.RequestID,
    commUnity_pb.Document,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    commUnity_pb.Document.deserializeBinary
  );

  getDocuments(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.Document> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetDocuments',
      request,
      metadata || {},
      this.methodDescriptorGetDocuments);
  }

  methodDescriptorGetDocumentCategories = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetDocumentCategories',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.RequestID,
    commUnity_pb.DocumentCategory,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    commUnity_pb.DocumentCategory.deserializeBinary
  );

  getDocumentCategories(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.DocumentCategory> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetDocumentCategories',
      request,
      metadata || {},
      this.methodDescriptorGetDocumentCategories);
  }

  methodDescriptorGetRestaurantSettings = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetRestaurantSettings',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.Empty,
    restaurant_pb.RestaurantSettings,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    restaurant_pb.RestaurantSettings.deserializeBinary
  );

  getRestaurantSettings(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<restaurant_pb.RestaurantSettings>;

  getRestaurantSettings(
    request: commUnity_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: restaurant_pb.RestaurantSettings) => void): grpcWeb.ClientReadableStream<restaurant_pb.RestaurantSettings>;

  getRestaurantSettings(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: restaurant_pb.RestaurantSettings) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/GetRestaurantSettings',
        request,
        metadata || {},
        this.methodDescriptorGetRestaurantSettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/GetRestaurantSettings',
    request,
    metadata || {},
    this.methodDescriptorGetRestaurantSettings);
  }

  methodDescriptorGetRestaurantProducts = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetRestaurantProducts',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.RequestID,
    restaurant_pb.RestaurantProduct,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    restaurant_pb.RestaurantProduct.deserializeBinary
  );

  getRestaurantProducts(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<restaurant_pb.RestaurantProduct> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetRestaurantProducts',
      request,
      metadata || {},
      this.methodDescriptorGetRestaurantProducts);
  }

  methodDescriptorGetTable = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetTable',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.RequestID,
    restaurant_pb.Table,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    restaurant_pb.Table.deserializeBinary
  );

  getTable(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null): Promise<restaurant_pb.Table>;

  getTable(
    request: commUnity_pb.RequestID,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: restaurant_pb.Table) => void): grpcWeb.ClientReadableStream<restaurant_pb.Table>;

  getTable(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: restaurant_pb.Table) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/GetTable',
        request,
        metadata || {},
        this.methodDescriptorGetTable,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/GetTable',
    request,
    metadata || {},
    this.methodDescriptorGetTable);
  }

  methodDescriptorGetRestaurantOrder = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetRestaurantOrder',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.RequestID,
    restaurant_pb.RestaurantOrder,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    restaurant_pb.RestaurantOrder.deserializeBinary
  );

  getRestaurantOrder(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null): Promise<restaurant_pb.RestaurantOrder>;

  getRestaurantOrder(
    request: commUnity_pb.RequestID,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: restaurant_pb.RestaurantOrder) => void): grpcWeb.ClientReadableStream<restaurant_pb.RestaurantOrder>;

  getRestaurantOrder(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: restaurant_pb.RestaurantOrder) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/GetRestaurantOrder',
        request,
        metadata || {},
        this.methodDescriptorGetRestaurantOrder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/GetRestaurantOrder',
    request,
    metadata || {},
    this.methodDescriptorGetRestaurantOrder);
  }

  methodDescriptorRestaurantCheckOut = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/RestaurantCheckOut',
    grpcWeb.MethodType.UNARY,
    restaurant_pb.CheckOutMeal,
    restaurant_pb.RestaurantOrderInfo,
    (request: restaurant_pb.CheckOutMeal) => {
      return request.serializeBinary();
    },
    restaurant_pb.RestaurantOrderInfo.deserializeBinary
  );

  restaurantCheckOut(
    request: restaurant_pb.CheckOutMeal,
    metadata?: grpcWeb.Metadata | null): Promise<restaurant_pb.RestaurantOrderInfo>;

  restaurantCheckOut(
    request: restaurant_pb.CheckOutMeal,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: restaurant_pb.RestaurantOrderInfo) => void): grpcWeb.ClientReadableStream<restaurant_pb.RestaurantOrderInfo>;

  restaurantCheckOut(
    request: restaurant_pb.CheckOutMeal,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: restaurant_pb.RestaurantOrderInfo) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/RestaurantCheckOut',
        request,
        metadata || {},
        this.methodDescriptorRestaurantCheckOut,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/RestaurantCheckOut',
    request,
    metadata || {},
    this.methodDescriptorRestaurantCheckOut);
  }

  methodDescriptorResetMeal = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/ResetMeal',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.RequestID,
    restaurant_pb.Meal,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    restaurant_pb.Meal.deserializeBinary
  );

  resetMeal(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null): Promise<restaurant_pb.Meal>;

  resetMeal(
    request: commUnity_pb.RequestID,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: restaurant_pb.Meal) => void): grpcWeb.ClientReadableStream<restaurant_pb.Meal>;

  resetMeal(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: restaurant_pb.Meal) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/ResetMeal',
        request,
        metadata || {},
        this.methodDescriptorResetMeal,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/ResetMeal',
    request,
    metadata || {},
    this.methodDescriptorResetMeal);
  }

  methodDescriptorGetMeal = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetMeal',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.RequestID,
    restaurant_pb.Meal,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    restaurant_pb.Meal.deserializeBinary
  );

  getMeal(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null): Promise<restaurant_pb.Meal>;

  getMeal(
    request: commUnity_pb.RequestID,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: restaurant_pb.Meal) => void): grpcWeb.ClientReadableStream<restaurant_pb.Meal>;

  getMeal(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: restaurant_pb.Meal) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/GetMeal',
        request,
        metadata || {},
        this.methodDescriptorGetMeal,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/GetMeal',
    request,
    metadata || {},
    this.methodDescriptorGetMeal);
  }

  methodDescriptorAddProductToMeal = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/AddProductToMeal',
    grpcWeb.MethodType.UNARY,
    restaurant_pb.RestaurantOrderLine,
    restaurant_pb.Meal,
    (request: restaurant_pb.RestaurantOrderLine) => {
      return request.serializeBinary();
    },
    restaurant_pb.Meal.deserializeBinary
  );

  addProductToMeal(
    request: restaurant_pb.RestaurantOrderLine,
    metadata?: grpcWeb.Metadata | null): Promise<restaurant_pb.Meal>;

  addProductToMeal(
    request: restaurant_pb.RestaurantOrderLine,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: restaurant_pb.Meal) => void): grpcWeb.ClientReadableStream<restaurant_pb.Meal>;

  addProductToMeal(
    request: restaurant_pb.RestaurantOrderLine,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: restaurant_pb.Meal) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/AddProductToMeal',
        request,
        metadata || {},
        this.methodDescriptorAddProductToMeal,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/AddProductToMeal',
    request,
    metadata || {},
    this.methodDescriptorAddProductToMeal);
  }

  methodDescriptorDropProductFromMeal = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/DropProductFromMeal',
    grpcWeb.MethodType.UNARY,
    mobile_pb.ProductToRemoveFromMeal,
    restaurant_pb.Meal,
    (request: mobile_pb.ProductToRemoveFromMeal) => {
      return request.serializeBinary();
    },
    restaurant_pb.Meal.deserializeBinary
  );

  dropProductFromMeal(
    request: mobile_pb.ProductToRemoveFromMeal,
    metadata?: grpcWeb.Metadata | null): Promise<restaurant_pb.Meal>;

  dropProductFromMeal(
    request: mobile_pb.ProductToRemoveFromMeal,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: restaurant_pb.Meal) => void): grpcWeb.ClientReadableStream<restaurant_pb.Meal>;

  dropProductFromMeal(
    request: mobile_pb.ProductToRemoveFromMeal,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: restaurant_pb.Meal) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/DropProductFromMeal',
        request,
        metadata || {},
        this.methodDescriptorDropProductFromMeal,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/DropProductFromMeal',
    request,
    metadata || {},
    this.methodDescriptorDropProductFromMeal);
  }

  methodDescriptorCallWaiter = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/CallWaiter',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.RequestID,
    commUnity_pb.Empty,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    commUnity_pb.Empty.deserializeBinary
  );

  callWaiter(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.Empty>;

  callWaiter(
    request: commUnity_pb.RequestID,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void): grpcWeb.ClientReadableStream<commUnity_pb.Empty>;

  callWaiter(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/CallWaiter',
        request,
        metadata || {},
        this.methodDescriptorCallWaiter,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/CallWaiter',
    request,
    metadata || {},
    this.methodDescriptorCallWaiter);
  }

  methodDescriptorGetShopSettings = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetShopSettings',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.Empty,
    shop_pb.ShopSettings,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    shop_pb.ShopSettings.deserializeBinary
  );

  getShopSettings(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<shop_pb.ShopSettings>;

  getShopSettings(
    request: commUnity_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shop_pb.ShopSettings) => void): grpcWeb.ClientReadableStream<shop_pb.ShopSettings>;

  getShopSettings(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shop_pb.ShopSettings) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/GetShopSettings',
        request,
        metadata || {},
        this.methodDescriptorGetShopSettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/GetShopSettings',
    request,
    metadata || {},
    this.methodDescriptorGetShopSettings);
  }

  methodDescriptorGetProducts = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetProducts',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.RequestID,
    shop_pb.Product,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    shop_pb.Product.deserializeBinary
  );

  getProducts(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<shop_pb.Product> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetProducts',
      request,
      metadata || {},
      this.methodDescriptorGetProducts);
  }

  methodDescriptorResetCart = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/ResetCart',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.RequestID,
    shop_pb.Cart,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    shop_pb.Cart.deserializeBinary
  );

  resetCart(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null): Promise<shop_pb.Cart>;

  resetCart(
    request: commUnity_pb.RequestID,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shop_pb.Cart) => void): grpcWeb.ClientReadableStream<shop_pb.Cart>;

  resetCart(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shop_pb.Cart) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/ResetCart',
        request,
        metadata || {},
        this.methodDescriptorResetCart,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/ResetCart',
    request,
    metadata || {},
    this.methodDescriptorResetCart);
  }

  methodDescriptorGetCart = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetCart',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.Empty,
    shop_pb.Cart,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    shop_pb.Cart.deserializeBinary
  );

  getCart(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<shop_pb.Cart>;

  getCart(
    request: commUnity_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shop_pb.Cart) => void): grpcWeb.ClientReadableStream<shop_pb.Cart>;

  getCart(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shop_pb.Cart) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/GetCart',
        request,
        metadata || {},
        this.methodDescriptorGetCart,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/GetCart',
    request,
    metadata || {},
    this.methodDescriptorGetCart);
  }

  methodDescriptorGetDeliveryCost = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetDeliveryCost',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.Empty,
    mobile_pb.DeliveryCost,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    mobile_pb.DeliveryCost.deserializeBinary
  );

  getDeliveryCost(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<mobile_pb.DeliveryCost>;

  getDeliveryCost(
    request: commUnity_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: mobile_pb.DeliveryCost) => void): grpcWeb.ClientReadableStream<mobile_pb.DeliveryCost>;

  getDeliveryCost(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: mobile_pb.DeliveryCost) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/GetDeliveryCost',
        request,
        metadata || {},
        this.methodDescriptorGetDeliveryCost,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/GetDeliveryCost',
    request,
    metadata || {},
    this.methodDescriptorGetDeliveryCost);
  }

  methodDescriptorAddProductToCart = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/AddProductToCart',
    grpcWeb.MethodType.UNARY,
    shop_pb.ProductOrderLine,
    shop_pb.Cart,
    (request: shop_pb.ProductOrderLine) => {
      return request.serializeBinary();
    },
    shop_pb.Cart.deserializeBinary
  );

  addProductToCart(
    request: shop_pb.ProductOrderLine,
    metadata?: grpcWeb.Metadata | null): Promise<shop_pb.Cart>;

  addProductToCart(
    request: shop_pb.ProductOrderLine,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shop_pb.Cart) => void): grpcWeb.ClientReadableStream<shop_pb.Cart>;

  addProductToCart(
    request: shop_pb.ProductOrderLine,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shop_pb.Cart) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/AddProductToCart',
        request,
        metadata || {},
        this.methodDescriptorAddProductToCart,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/AddProductToCart',
    request,
    metadata || {},
    this.methodDescriptorAddProductToCart);
  }

  methodDescriptorDropProductFromCart = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/DropProductFromCart',
    grpcWeb.MethodType.UNARY,
    mobile_pb.ProductToRemove,
    shop_pb.Cart,
    (request: mobile_pb.ProductToRemove) => {
      return request.serializeBinary();
    },
    shop_pb.Cart.deserializeBinary
  );

  dropProductFromCart(
    request: mobile_pb.ProductToRemove,
    metadata?: grpcWeb.Metadata | null): Promise<shop_pb.Cart>;

  dropProductFromCart(
    request: mobile_pb.ProductToRemove,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shop_pb.Cart) => void): grpcWeb.ClientReadableStream<shop_pb.Cart>;

  dropProductFromCart(
    request: mobile_pb.ProductToRemove,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shop_pb.Cart) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/DropProductFromCart',
        request,
        metadata || {},
        this.methodDescriptorDropProductFromCart,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/DropProductFromCart',
    request,
    metadata || {},
    this.methodDescriptorDropProductFromCart);
  }

  methodDescriptorCheckOutCart = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/CheckOutCart',
    grpcWeb.MethodType.UNARY,
    shop_pb.CheckOut,
    shop_pb.OrderInfo,
    (request: shop_pb.CheckOut) => {
      return request.serializeBinary();
    },
    shop_pb.OrderInfo.deserializeBinary
  );

  checkOutCart(
    request: shop_pb.CheckOut,
    metadata?: grpcWeb.Metadata | null): Promise<shop_pb.OrderInfo>;

  checkOutCart(
    request: shop_pb.CheckOut,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shop_pb.OrderInfo) => void): grpcWeb.ClientReadableStream<shop_pb.OrderInfo>;

  checkOutCart(
    request: shop_pb.CheckOut,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shop_pb.OrderInfo) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/CheckOutCart',
        request,
        metadata || {},
        this.methodDescriptorCheckOutCart,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/CheckOutCart',
    request,
    metadata || {},
    this.methodDescriptorCheckOutCart);
  }

  methodDescriptorCheckPromotionCode = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/CheckPromotionCode',
    grpcWeb.MethodType.UNARY,
    shop_pb.CheckPromoCode,
    shop_pb.Discount,
    (request: shop_pb.CheckPromoCode) => {
      return request.serializeBinary();
    },
    shop_pb.Discount.deserializeBinary
  );

  checkPromotionCode(
    request: shop_pb.CheckPromoCode,
    metadata?: grpcWeb.Metadata | null): Promise<shop_pb.Discount>;

  checkPromotionCode(
    request: shop_pb.CheckPromoCode,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shop_pb.Discount) => void): grpcWeb.ClientReadableStream<shop_pb.Discount>;

  checkPromotionCode(
    request: shop_pb.CheckPromoCode,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shop_pb.Discount) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/CheckPromotionCode',
        request,
        metadata || {},
        this.methodDescriptorCheckPromotionCode,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/CheckPromotionCode',
    request,
    metadata || {},
    this.methodDescriptorCheckPromotionCode);
  }

  methodDescriptorGetProductOrders = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetProductOrders',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.RequestID,
    shop_pb.ProductOrder,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    shop_pb.ProductOrder.deserializeBinary
  );

  getProductOrders(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<shop_pb.ProductOrder> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetProductOrders',
      request,
      metadata || {},
      this.methodDescriptorGetProductOrders);
  }

  methodDescriptorAddDeliveryAddress = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/AddDeliveryAddress',
    grpcWeb.MethodType.UNARY,
    shop_pb.DeliveryAddress,
    commUnity_pb.Empty,
    (request: shop_pb.DeliveryAddress) => {
      return request.serializeBinary();
    },
    commUnity_pb.Empty.deserializeBinary
  );

  addDeliveryAddress(
    request: shop_pb.DeliveryAddress,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.Empty>;

  addDeliveryAddress(
    request: shop_pb.DeliveryAddress,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void): grpcWeb.ClientReadableStream<commUnity_pb.Empty>;

  addDeliveryAddress(
    request: shop_pb.DeliveryAddress,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/AddDeliveryAddress',
        request,
        metadata || {},
        this.methodDescriptorAddDeliveryAddress,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/AddDeliveryAddress',
    request,
    metadata || {},
    this.methodDescriptorAddDeliveryAddress);
  }

  methodDescriptorDropDeliveryAddress = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/DropDeliveryAddress',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.RequestID,
    commUnity_pb.Empty,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    commUnity_pb.Empty.deserializeBinary
  );

  dropDeliveryAddress(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.Empty>;

  dropDeliveryAddress(
    request: commUnity_pb.RequestID,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void): grpcWeb.ClientReadableStream<commUnity_pb.Empty>;

  dropDeliveryAddress(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/DropDeliveryAddress',
        request,
        metadata || {},
        this.methodDescriptorDropDeliveryAddress,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/DropDeliveryAddress',
    request,
    metadata || {},
    this.methodDescriptorDropDeliveryAddress);
  }

  methodDescriptorGetDeliveryAddresses = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetDeliveryAddresses',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.RequestID,
    shop_pb.DeliveryAddress,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    shop_pb.DeliveryAddress.deserializeBinary
  );

  getDeliveryAddresses(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<shop_pb.DeliveryAddress> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetDeliveryAddresses',
      request,
      metadata || {},
      this.methodDescriptorGetDeliveryAddresses);
  }

  methodDescriptorGetDisclaimer = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetDisclaimer',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.Empty,
    commUnity_pb.Disclaimer,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.Disclaimer.deserializeBinary
  );

  getDisclaimer(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.Disclaimer>;

  getDisclaimer(
    request: commUnity_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.Disclaimer) => void): grpcWeb.ClientReadableStream<commUnity_pb.Disclaimer>;

  getDisclaimer(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.Disclaimer) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/GetDisclaimer',
        request,
        metadata || {},
        this.methodDescriptorGetDisclaimer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/GetDisclaimer',
    request,
    metadata || {},
    this.methodDescriptorGetDisclaimer);
  }

  methodDescriptorApproveDisclaimer = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/ApproveDisclaimer',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.Empty,
    commUnity_pb.Empty,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    commUnity_pb.Empty.deserializeBinary
  );

  approveDisclaimer(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.Empty>;

  approveDisclaimer(
    request: commUnity_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void): grpcWeb.ClientReadableStream<commUnity_pb.Empty>;

  approveDisclaimer(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/ApproveDisclaimer',
        request,
        metadata || {},
        this.methodDescriptorApproveDisclaimer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/ApproveDisclaimer',
    request,
    metadata || {},
    this.methodDescriptorApproveDisclaimer);
  }

  methodDescriptorGetAvailableMembers = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetAvailableMembers',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.RequestID,
    commUnity_pb.Member,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    commUnity_pb.Member.deserializeBinary
  );

  getAvailableMembers(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<commUnity_pb.Member> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetAvailableMembers',
      request,
      metadata || {},
      this.methodDescriptorGetAvailableMembers);
  }

  methodDescriptorGetAvailableTables = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetAvailableTables',
    grpcWeb.MethodType.SERVER_STREAMING,
    restaurant_pb.EventTableRequest,
    restaurant_pb.Table,
    (request: restaurant_pb.EventTableRequest) => {
      return request.serializeBinary();
    },
    restaurant_pb.Table.deserializeBinary
  );

  getAvailableTables(
    request: restaurant_pb.EventTableRequest,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<restaurant_pb.Table> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetAvailableTables',
      request,
      metadata || {},
      this.methodDescriptorGetAvailableTables);
  }

  methodDescriptorAddEventTableReservation = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/AddEventTableReservation',
    grpcWeb.MethodType.UNARY,
    restaurant_pb.EventTableReservation,
    commUnity_pb.Empty,
    (request: restaurant_pb.EventTableReservation) => {
      return request.serializeBinary();
    },
    commUnity_pb.Empty.deserializeBinary
  );

  addEventTableReservation(
    request: restaurant_pb.EventTableReservation,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.Empty>;

  addEventTableReservation(
    request: restaurant_pb.EventTableReservation,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void): grpcWeb.ClientReadableStream<commUnity_pb.Empty>;

  addEventTableReservation(
    request: restaurant_pb.EventTableReservation,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/AddEventTableReservation',
        request,
        metadata || {},
        this.methodDescriptorAddEventTableReservation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/AddEventTableReservation',
    request,
    metadata || {},
    this.methodDescriptorAddEventTableReservation);
  }

  methodDescriptorCancelEventTableReservation = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/CancelEventTableReservation',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.RequestID,
    commUnity_pb.Empty,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    commUnity_pb.Empty.deserializeBinary
  );

  cancelEventTableReservation(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.Empty>;

  cancelEventTableReservation(
    request: commUnity_pb.RequestID,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void): grpcWeb.ClientReadableStream<commUnity_pb.Empty>;

  cancelEventTableReservation(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/CancelEventTableReservation',
        request,
        metadata || {},
        this.methodDescriptorCancelEventTableReservation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/CancelEventTableReservation',
    request,
    metadata || {},
    this.methodDescriptorCancelEventTableReservation);
  }

  methodDescriptorGetEventTableReservation = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetEventTableReservation',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.RequestID,
    restaurant_pb.EventTableReservation,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    restaurant_pb.EventTableReservation.deserializeBinary
  );

  getEventTableReservation(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null): Promise<restaurant_pb.EventTableReservation>;

  getEventTableReservation(
    request: commUnity_pb.RequestID,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: restaurant_pb.EventTableReservation) => void): grpcWeb.ClientReadableStream<restaurant_pb.EventTableReservation>;

  getEventTableReservation(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: restaurant_pb.EventTableReservation) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/GetEventTableReservation',
        request,
        metadata || {},
        this.methodDescriptorGetEventTableReservation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/GetEventTableReservation',
    request,
    metadata || {},
    this.methodDescriptorGetEventTableReservation);
  }

  methodDescriptorGetHotixSettings = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetHotixSettings',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.Empty,
    hotix_pb.HotixSettings,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    hotix_pb.HotixSettings.deserializeBinary
  );

  getHotixSettings(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<hotix_pb.HotixSettings>;

  getHotixSettings(
    request: commUnity_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: hotix_pb.HotixSettings) => void): grpcWeb.ClientReadableStream<hotix_pb.HotixSettings>;

  getHotixSettings(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: hotix_pb.HotixSettings) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/GetHotixSettings',
        request,
        metadata || {},
        this.methodDescriptorGetHotixSettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/GetHotixSettings',
    request,
    metadata || {},
    this.methodDescriptorGetHotixSettings);
  }

  methodDescriptorGetHotixReservations = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetHotixReservations',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    hotix_pb.HotixReservation,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    hotix_pb.HotixReservation.deserializeBinary
  );

  getHotixReservations(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<hotix_pb.HotixReservation> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetHotixReservations',
      request,
      metadata || {},
      this.methodDescriptorGetHotixReservations);
  }

  methodDescriptorGetHotixReservationDetails = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetHotixReservationDetails',
    grpcWeb.MethodType.SERVER_STREAMING,
    commUnity_pb.Empty,
    hotix_pb.HotixReservationDetail,
    (request: commUnity_pb.Empty) => {
      return request.serializeBinary();
    },
    hotix_pb.HotixReservationDetail.deserializeBinary
  );

  getHotixReservationDetails(
    request: commUnity_pb.Empty,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<hotix_pb.HotixReservationDetail> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/commUnity.Mobile/GetHotixReservationDetails',
      request,
      metadata || {},
      this.methodDescriptorGetHotixReservationDetails);
  }

  methodDescriptorGetHotixPreCheckin = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetHotixPreCheckin',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.RequestID,
    hotix_pb.HotixPreCheckin,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    hotix_pb.HotixPreCheckin.deserializeBinary
  );

  getHotixPreCheckin(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null): Promise<hotix_pb.HotixPreCheckin>;

  getHotixPreCheckin(
    request: commUnity_pb.RequestID,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: hotix_pb.HotixPreCheckin) => void): grpcWeb.ClientReadableStream<hotix_pb.HotixPreCheckin>;

  getHotixPreCheckin(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: hotix_pb.HotixPreCheckin) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/GetHotixPreCheckin',
        request,
        metadata || {},
        this.methodDescriptorGetHotixPreCheckin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/GetHotixPreCheckin',
    request,
    metadata || {},
    this.methodDescriptorGetHotixPreCheckin);
  }

  methodDescriptorSubmitHotixPreCheckin = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/SubmitHotixPreCheckin',
    grpcWeb.MethodType.UNARY,
    hotix_pb.HotixPreCheckin,
    commUnity_pb.Empty,
    (request: hotix_pb.HotixPreCheckin) => {
      return request.serializeBinary();
    },
    commUnity_pb.Empty.deserializeBinary
  );

  submitHotixPreCheckin(
    request: hotix_pb.HotixPreCheckin,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.Empty>;

  submitHotixPreCheckin(
    request: hotix_pb.HotixPreCheckin,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void): grpcWeb.ClientReadableStream<commUnity_pb.Empty>;

  submitHotixPreCheckin(
    request: hotix_pb.HotixPreCheckin,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/SubmitHotixPreCheckin',
        request,
        metadata || {},
        this.methodDescriptorSubmitHotixPreCheckin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/SubmitHotixPreCheckin',
    request,
    metadata || {},
    this.methodDescriptorSubmitHotixPreCheckin);
  }

  methodDescriptorGetHotixRoomBillDetails = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/GetHotixRoomBillDetails',
    grpcWeb.MethodType.UNARY,
    commUnity_pb.RequestID,
    hotix_pb.HotixRoomBillDetailResp,
    (request: commUnity_pb.RequestID) => {
      return request.serializeBinary();
    },
    hotix_pb.HotixRoomBillDetailResp.deserializeBinary
  );

  getHotixRoomBillDetails(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null): Promise<hotix_pb.HotixRoomBillDetailResp>;

  getHotixRoomBillDetails(
    request: commUnity_pb.RequestID,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: hotix_pb.HotixRoomBillDetailResp) => void): grpcWeb.ClientReadableStream<hotix_pb.HotixRoomBillDetailResp>;

  getHotixRoomBillDetails(
    request: commUnity_pb.RequestID,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: hotix_pb.HotixRoomBillDetailResp) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/GetHotixRoomBillDetails',
        request,
        metadata || {},
        this.methodDescriptorGetHotixRoomBillDetails,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/GetHotixRoomBillDetails',
    request,
    metadata || {},
    this.methodDescriptorGetHotixRoomBillDetails);
  }

  methodDescriptorSetHotixSatisfactionSurvey = new grpcWeb.MethodDescriptor(
    '/commUnity.Mobile/SetHotixSatisfactionSurvey',
    grpcWeb.MethodType.UNARY,
    hotix_pb.HotixSatisfactionSurvey,
    commUnity_pb.Empty,
    (request: hotix_pb.HotixSatisfactionSurvey) => {
      return request.serializeBinary();
    },
    commUnity_pb.Empty.deserializeBinary
  );

  setHotixSatisfactionSurvey(
    request: hotix_pb.HotixSatisfactionSurvey,
    metadata?: grpcWeb.Metadata | null): Promise<commUnity_pb.Empty>;

  setHotixSatisfactionSurvey(
    request: hotix_pb.HotixSatisfactionSurvey,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void): grpcWeb.ClientReadableStream<commUnity_pb.Empty>;

  setHotixSatisfactionSurvey(
    request: hotix_pb.HotixSatisfactionSurvey,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: commUnity_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/commUnity.Mobile/SetHotixSatisfactionSurvey',
        request,
        metadata || {},
        this.methodDescriptorSetHotixSatisfactionSurvey,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/commUnity.Mobile/SetHotixSatisfactionSurvey',
    request,
    metadata || {},
    this.methodDescriptorSetHotixSatisfactionSurvey);
  }

}

